import './style.scss';

import React from 'react';

import BaseCarousel from '@widgets/carousels/base/BaseCarousel';
import ContentCarouselButtons from '../shared/content-carousel-buttons/ContentCarouselButtons';
import HeaderContentCarousel from '../shared/header-content-carousel/HeaderContentCarousel';
import CarouselItem from './CarouselItem';
import type { EmblaOptionsType } from 'embla-carousel-react';
import type { ContentCarouselWithThumbnail, ContentCarouselItemWithThumbnail } from '@components/models';

const ContentCarouselWithThumbnailOptions = ({
    id,
    title,
    page_model,
    slug,
    link_details,
    use_big_thumbnail,
    items,
    type
}: ContentCarouselWithThumbnail) => {
    const OPTIONS: EmblaOptionsType = {
        dragFree: true,
        containScroll: 'trimSnaps',
        slidesToScroll: 3,
    };

    return (
        <BaseCarousel
            renderHeaderNode={<HeaderContentCarousel title={title} link={link_details} />}
            renderNavComponents={ContentCarouselButtons}
            extraClassForContainer={{
                classNames: 'content-carousel-with-thumbnail-options__container',
                overrideBaseClass: false,
            }}
            extraClassForViewport={{
                classNames: 'content-carousel-with-thumbnail-options__viewport',
                overrideBaseClass: false,
            }}
            options={OPTIONS}
        >
            {items.map((i: ContentCarouselItemWithThumbnail, idx: number) => (
                <CarouselItem
                    {...i}
                    key={i.id}
                    componentId={id}
                    id={i.id}
                    idx={idx}
                    title={i.title}
                    img={i.img}
                    url_path={i.url_path}
                    slug={slug}
                    page_model={page_model}
                    use_big_thumbnail={use_big_thumbnail}
                    type={type}
                    hover_img={i.hover_img}
                />
            ))}
        </BaseCarousel>
    );
};

export default ContentCarouselWithThumbnailOptions;
