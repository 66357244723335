import React, { useState } from 'react';
import type { ContentCarouselItemWithThumbnail } from '@components/models';
import ImageWidget from '@widgets/image-widget/ImageWidget';
import LinkDetails, { LINK_TYPE, LINK_UI } from '@widgets/link-details-widget/LinkDetails';
import classNames from 'classnames';

interface Props extends ContentCarouselItemWithThumbnail {
    componentId: number;
    id: number;
    idx: number;
    slug: string;
    page_model: string;
    use_big_thumbnail?: boolean;
    type?: string
}

const CarouselItem = ({
    componentId,
    id,
    idx,
    title,
    sub_title,
    slug,
    page_model,
    img,
    use_big_thumbnail,
    url_path,
    bg_text_url,
    extra_text_for_link,
    type,
    hover_img,
}: Props) => {
    const [currentImg, setCurrentImg] = useState(img.url); // Default to the original image

    const contentThumbnailClasses = classNames({
        'content-thumbnail__regular': !use_big_thumbnail && type !== 'categoryCarousel',
        'content-thumbnail__large': use_big_thumbnail && type !== 'categoryCarousel',
        'category-content-thumbnail__regular': !use_big_thumbnail && type === 'categoryCarousel',
        'category-content-thumbnail__large': use_big_thumbnail && type === 'categoryCarousel',
    });

    const dataSection = title ?? `${slug} ${page_model} page content carousel w/ thumbnail ${componentId}`;
    const dataOutcome = title ?? `${slug} ${page_model} page content carousel w/ thumbnail ${componentId} title`;
    const dataItemValue = title ?? `${slug} ${page_model} page content carousel w/ thumbnail ${componentId} sub-component ${id}`;

    const resolvedType = type === 'categoryCarousel2' ? 'categoryCarousel' : type || (hover_img ? 'categoryCarousel' : undefined);

    return (
        <div 
            className={resolvedType === 'categoryCarousel' ? 'category-content-carousel-thumbnail-item' : 'content-carousel-thumbnail-item'} 
            data-testid="content-carousel-thumbnail-item"
        >
            <LinkDetails
                linkType={LINK_TYPE.LINK}
                linkUI={LINK_UI.PLACEHOLDER}
                url_path={url_path}
                gaTrackingDetails={{
                    data_section: dataSection,
                    data_outcome: dataOutcome,
                    data_component_item: 'carousel item',
                    data_item_value: dataItemValue,
                }}
                testId={`content-carousel-thumbnail-item__url-${idx}`}
            >
                <div
                    className={`${resolvedType === 'categoryCarousel' ? 'category-content-thumbnail' : 'content-thumbnail'} ${contentThumbnailClasses}`}
                    data-testid={`content-carousel-thumbnail__container-${idx}`}
                    onMouseEnter={() => hover_img ? setCurrentImg(hover_img.url) : null} // Set hover image
                    onMouseLeave={() => setCurrentImg(img.url)} // Revert to original image
                >
                    <ImageWidget alt={img.name} src={currentImg} testId={`content-carousel-thumbnail-item__img-${idx}`} />
                </div>
                <div className={resolvedType === 'categoryCarousel' ? "category-content-carousel-thumbnail-item__content" : "content-carousel-thumbnail-item__content"}>
                    <div>
                        <h1
                            className="text-truncate-after-2nd-line"
                            data-testid={`content-carousel-thumbnail-item__title-${idx}`}
                        >
                            {title}
                        </h1>
                        {sub_title && <h2 className="text-truncate-after-2nd-line">{sub_title}</h2>}
                    </div>
                    {url_path && extra_text_for_link && (
                        <div
                            className="link-details"
                            data-testid={`content-carousel-thumbnail-item__linkdetails-${idx}`}
                            style={{ color: bg_text_url ?? '' }}
                        >
                            {extra_text_for_link}
                        </div>
                    )}
                </div>
            </LinkDetails>
        </div>
    );
};

export default CarouselItem;
